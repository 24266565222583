import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { modalActions } from '../components/Modals/state'
import { Header, Container, Grid, Segment } from 'semantic-ui-react'
import { withTranslation } from 'react-i18next'
import Img from 'gatsby-image'
import MapContact from '../components/Map/MapContact'

import { getFluidImage, getRawImage } from '../utils/images'
import Layout from '../components/Layouts/PageLayout'
import PageHeader from '../components/Items/PageHeader'

class ReiseBieroFinder extends Component {
  render () {
    const { t, pageContext } = this.props
    const cloudinary = 'wordpress/Header_Reisebüro-Finder_tnhry5'
    const transformation = ''

    return (
      <>
        <Helmet>
          <title>
            {t('reisebuero-finder.header.title') + t('htmlTitleSuffix')}
          </title>

          <meta name='description' content={t('contact.header.subtitle')} />
          <meta name='image' content={getRawImage({ cloudinary })} />

          <meta property='og:title' content={t('contact.header.title')} />
          <meta
            property='og:description'
            content={t('contact.header.subtitle')}
          />
          <meta property='og:image' content={getRawImage({ cloudinary })} />

          <meta name='twitter:card' content='summary_large_image' />
          <meta name='twitter:creator' content='Tischler Reisen' />
          <meta name='twitter:title' content={t('contact.header.title')} />
          <meta
            name='twitter:description'
            content={t('contact.header.subtitle')}
          />
          <meta name='twitter:image' content={getRawImage({ cloudinary })} />
        </Helmet>

        <Layout pageContext={pageContext}>
          <Img
            loading='lazy'
            fadeIn={false}
            fluid={getFluidImage({ cloudinary, transformation, ar: 2.5 })}
            alt={t('contact.image.alt')}
          />

          <PageHeader title={t('reisebuero-finder.pageTitle')} />

          <Segment vertical padded='very' basic style={{ width: '100%' }}>
            <Container>
              <Grid style={{ maxWidth: '650px', margin: 'auto' }}>
                <Grid.Row>
                  <Segment vertical basic>
                    <p>
                      Die Tischler Reisewelten finden Sie auch in vielen
                      Reisebüros in Deutschland, Österreich, Luxemburg und
                      Südtirol. Buchen Sie Ihre Tischler Traumreise im Reisebüro
                      Ihres Vertrauens oder finden Sie ein passendes Reisebüro
                      ganz in Ihrer Nähe.
                    </p>
                  </Segment>
                </Grid.Row>
                <Grid.Row>
                  <Segment vertical basic style={{ width: '100%' }}>
                    <Header as='h2' color='red'>
                      {t('contact.location')}
                    </Header>
                    <MapContact mapMarkers={pageContext.mapMarkers} />
                  </Segment>
                </Grid.Row>
              </Grid>
            </Container>
          </Segment>
        </Layout>
      </>
    )
  }
}
/**************************************************************
 * REDUX
 **************************************************************/
const mapDispatchToProps = {
  toggleModal: modalActions.toggleModal
}

export default withTranslation()(
  connect(null, mapDispatchToProps)(ReiseBieroFinder)
)
