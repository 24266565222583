import React, { useEffect, useState } from 'react'
import { Button, Segment, Input, Icon, Divider, Form } from 'semantic-ui-react'
import { connect } from 'react-redux'
import GoogleMapReact from 'google-map-react'
import MarkerClusterer from '@google/markerclusterer'
import { withTranslation } from 'react-i18next'
import { isEmpty, map as loMap } from 'lodash'
import { googleMapsActions } from '../CookieConfirmation/state'
import Usercentrics from '@usercentrics/cmp-browser-sdk'

import mapPlaceholder from '../../assets/map_placeholder-black_and_white.jpg'

const DEFAULT_CENTER = { lat: 52.1618943, lng: 9.0008456 }

const MapContact = ({
  height = 500,
  t,
  googleMapsEnable,
  googleMapsEnableHandler,
  mapMarkers
}) => {
  const [loaded, setLoaded] = useState(false)
  const [searching, setSearching] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const [center, setCenter] = useState(DEFAULT_CENTER)
  const [PLZ, setPLZ] = useState('')
  let [reload, setReload] = useState(0)

  const UsercentricsCMP = enableMap => {
    const UC = new Usercentrics('5gd5mnQUH')

    UC.init().then(() => {
      // getCategories() returns all categories' and data processing services' information
      const categories = UC.getCategories()

      const onSaveHandler = userDecisions => {
        // UserDecisions needs to include all the user choices for each service that were made in your UI
        UC.updateServices(userDecisions).then(() => {
          // Remember to fetch the now updated categories
          googleMapsEnableHandler()
        })
      }

      if (categories[1].services[2].consent.status) {
        googleMapsEnableHandler()
      }

      if (enableMap) {
        const userDecisions = [{ serviceId: 'S1pcEj_jZX', status: true }]
        onSaveHandler(userDecisions)
      }
    })

    return false
  }

  useEffect(() => {
    if (googleMapsEnable === 'true' && !loaded) {
      // Init cluster function script
      const script = document.createElement('script')
      script.src =
        'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/markerclusterer.js'
      script.async = true
      script.setAttribute('data-usercentrics', 'Google Maps')
      script.type = 'text/plain'
      document.body.appendChild(script)
      setLoaded(true)
    }
    UsercentricsCMP()
  }, [googleMapsEnable])

  const startFilter = () => {
    setSearching(true)
    setNotFound(false)
    setReload(reload + 1)
  }

  function renderMarkers (map, maps) {
    const markers = []
    const infoWindow = new maps.InfoWindow()

    // Click on map -> close infoWindow
    maps.event.addListener(map, 'click', function (event) {
      infoWindow.close()
    })

    let lastMarkerPosition

    // Generate markers
    loMap(mapMarkers, (marker, i) => {
      if (!PLZ || marker.PLZ === PLZ) {
        const markerPoint = new maps.Marker({
          position: { lat: +marker.Latitude, lng: +marker.Longitude },
          map
        })
        // Function for opening modal
        markerPoint.addListener('click', () => {
          infoWindow.setContent(marker.Content)
          infoWindow.open(map, markerPoint)
        })
        lastMarkerPosition = { lat: +marker.Latitude, lng: +marker.Longitude }
        markers[i] = markerPoint
      }
    })

    if (isEmpty(markers)) {
      setNotFound(true)
    }

    if (PLZ && !isEmpty(markers)) {
      setCenter(lastMarkerPosition)
    } else {
      setCenter(DEFAULT_CENTER)
    }

    // Enable Cluster function
    const cluser = new MarkerClusterer(map, markers, {
      imagePath:
        'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
    })

    setSearching(false)

    return cluser
  }

  // Cookie placeholder map
  if (googleMapsEnable !== 'true') {
    return (
      <Segment
        vertical
        basic
        style={{
          width: '100%',
          height,
          backgroundImage: 'url(' + mapPlaceholder + ')',
          backgroundSize: '',
          backgroundRepeat: 'no-repeat',
          textAlign: 'center',
          paddingTop: '200px'
        }}
      >
        {t('footer.cookies.mapInfo')}
        <br />
        <Button
          color='red'
          style={{ marginBottom: 10, marginTop: 10 }}
          onClick={() => {
            UsercentricsCMP(true)
          }}
        >
          {t('footer.cookies.mapAgree')}
        </Button>
      </Segment>
    )
  }

  if (loaded) {
    return (
      <Segment vertical basic style={{ width: '100%', height }}>
        <Form
          onSubmit={() => {
            startFilter()
          }}
        >
          <Input
            loading={searching}
            placeholder='PLZ eingeben'
            value={PLZ}
            icon={
              <Icon
                name='search'
                onClick={() => {
                  startFilter()
                }}
                inverted
                circular
                link
              />
            }
            onChange={e => setPLZ(e.target.value)}
          />
        </Form>
        <Divider />
        {loaded && (
          <>
            {!notFound ? (
              <GoogleMapReact
                key={reload}
                yesIWantToUseGoogleMapApiInternals
                bootstrapURLKeys={{ key: process.env.MAP_API_KEY }}
                center={center}
                defaultZoom={PLZ ? 13 : 4}
                onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
              />
            ) : (
              <Segment
                vertical
                basic
                style={{
                  width: '100%',
                  height,
                  backgroundImage: 'url(' + mapPlaceholder + ')',
                  backgroundSize: '',
                  backgroundRepeat: 'no-repeat',
                  textAlign: 'center',
                  paddingTop: '200px',
                  paddingLeft: '40px',
                  paddingRight: '40px'
                }}
              >
                Leider haben wir unter der eingegebenen PLZ kein Reisebüro
                gefunden – senden Sie Ihre Anfrage gerne an unser Team über
                unser Kontaktformular
                {/* <AnchorLink offset='500' href='#hs-form'>
                  <Button className='mini ui button olive'>
                    Kontaktformular
                  </Button>{' '}
                </AnchorLink> */}{' '}
                oder rufen Sie uns an: Tel.:{' '}
                <a href='tel:+49 8821 931750'>+49 8821 931750</a>
              </Segment>
            )}
          </>
        )}
      </Segment>
    )
  }

  return false
}

/**************************************************************
 * REDUX
 **************************************************************/
const mapDispatchToProps = {
  googleMapsEnableHandler: googleMapsActions.googleMapsEnable
}

const mapStateToProps = ({ googleMaps }) => ({
  googleMapsEnable: googleMaps.googleMapsEnable
})

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(MapContact)
)
